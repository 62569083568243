import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import StudentModel from "../models/StudentModel";
import StudentAPI from "../services/StudentAPI";
import ExamModel from "../models/ExamModel";

class StudentStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();

    extendObservable(this, {
      studentsList: [],
      studentListSelect: undefined,
      student: undefined,
      totalPages: 0,
      size: 20,
      page: 0,
      sort: "created,desc",
      examsList: [],
      exam: undefined,
      filters: {},
      loading: false,
      channels: [],
    });
  }

  reset() {
    this.studentsList = [];
    this.studentListSelect = undefined;
    this.student = undefined;
    this.totalPages = 0;
    this.size = 20;
    this.page = 0;
    this.sort = "created,desc";
    this.examsList = [];
    this.exam = undefined;
    this.loading = false;
    this.channels = [];
    this.channelsRemove = [];
    this.channelsAdd = [];
  }

  initializeStudent() {
    this.student = new StudentModel();
  }

  async get(uuid) {
    this.loading = true;
    const response = await StudentAPI.get(uuid);
    this.loading = false;
    this.student = new StudentModel(response);
  }

  async getList(
    size = 20,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await StudentAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;

    if (response.error) {
      this.studentsList = [];
      return;
    }

    this.studentsList = response.content.map(
      (student) => new StudentModel(student)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
  }

  async setPage(page, size = 20, sort = "created,desc") {
    this.page = page;
    this.size = size;
    this.sort = sort;
    await this.getList(this.size, page);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, sort);
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  async getExamsByStudent(uuidStudent) {
    this.loading = true;
    const response = await StudentAPI.getExamsByStudent(uuidStudent);
    this.loading = false;

    if (response.error) {
      this.examsList = [];
      return;
    }

    this.examsList = response.map((exam) => new ExamModel(exam));
  }

  async getExam(uuidStudent, uuidExam) {
    this.loading = true;
    const response = await StudentAPI.getExam(uuidStudent, uuidExam);
    this.loading = false;
    this.exam = new ExamModel(response);
  }

  async save() {
    this.loading = true;

    const data = JSON.stringify({
      ...this.student,
    });

    const response = await StudentAPI.save(data);

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Salvo com sucesso!");
    }

    this.loading = false;
    return response;
  }

  async update() {
    this.loading = true;

    const data = JSON.stringify({
      ...this.student,
    });

    const response = await StudentAPI.update(this.student?.uuid, data);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Atualizado com sucesso!");

    }

    return response;
  }

  async onDelete(uuid) {
    this.loading = true;
    const response = await StudentAPI.delete(uuid);
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Deletado com sucesso!");
    }

    return response;
  }

  async onChange(prop, value) {
    const student = this.student;
    switch (prop) {
      case "state":
        student.state = value ? value.value : undefined;
        break;
      default:
        student[prop] = value;
    }
    this.student = new StudentModel(student);
  }
}

export default StudentStore;
