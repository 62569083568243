import BaseModel from "./BaseModel";

class StudentModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.name = data.name;
    this.email = data.email;
    this.ra = data.ra;
    this.teamName = data.teamName;
    this.university = data.university;
    this.city = data.city;
    this.state = data.state;
    this.exams= data.exams;
  }
}

export default StudentModel;
