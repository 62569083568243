/* eslint-disable class-methods-use-this */
import { extendObservable } from "mobx";

import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";
import ItemMenuModel from "~/models/ItemMenuModel";

// const ManagerPropertyContainer = lazy(() => import('../containers/ManagerPropertyContainer'));

class MenuStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, {
      menus: [],
      dashboard: new ItemMenuModel(this.dashboardData),
      itemMenu: undefined,
      subItemMenu: undefined,
    });
  }

  /** Carrega menu */
  loadMenu() {
    const showMenuStoom = this.rootStore.authStore.isStoomAdmin;
    let items = this.itemsMenu;

    if (!showMenuStoom) {
      items = this.itemsMenu.filter((item) => item.title !== "Stoom");
    }

    this.menus = items.map((item) => new ItemMenuModel(item));
    if (StorageUtil.getItem(KEYS.PATH_MENU)) {
      this.setCurrentMenu(StorageUtil.getItem(KEYS.PATH_MENU));
    }
  }

  /** Altera o menu atual. */
  setCurrentMenu(path) {
    StorageUtil.setItem(KEYS.PATH_MENU, path);
    this.itemMenu = this.menus.find((item) => {
      const sub = item.subItems.find((subItem) => subItem.path === path);
      if (sub) {
        this.subItemMenu = sub;
        return item;
      }
      return null;
    });
  }

  /** Retorna o titulo do menu selecionado */
  get title() {
    if (!this.itemMenu) return this.dashboard.title;
    return this.itemMenu && this.itemMenu.title;
  }

  /** Retorna o subtitulo do submenu */
  get subtitle() {
    if (!this.subItemMenu) return this.dashboard.subtitle;
    return this.subItemMenu && this.subItemMenu.title;
  }

  /** Retorna se dashboard está visible */
  get dashboardVisible() {
    return !this.itemMenu;
  }

  /** Dados da dashboard */
  // get dashboardData() {
  //   return {
  //     title: "Dashboard",
  //     subtitle: "Um painel completo para controlar seu site",
  //     icon: "fas fa-clipboard-list",
  //     subItems: [],
  //   };
  // }

  /** Items de menu */
  get itemsMenu() {
    const routerPath = process.env.REACT_APP_ROUTE_PATH || ''
    return Object.values({
      // usuarios: {
      //   title: "Clientes",
      //   icon: "fas fa-users",
      //   subItems: [
      //     { path: "/cadastrar-clientes-novo", title: "Novo Cliente" },
      //     { path: "/clientes", title: "Clientes" },
      //   ],
      // },
      administrators: {
        title: "Administradores",
        subtitle: "Área de administradores",
        icon: "fas fa-user-shield",
        subItems: [
          {
            path: `${routerPath}/cadastrar-administrador`,
            title: "Novo Administrador",
          },
          { path: `${routerPath}/administradores`, title: "Administradores" },
        ],
      },
      // branches: {
      //   title: "Filiais",
      //   subtitle: "Área de filial",
      //   icon: "fas fa-light fa-suitcase",
      //   subItems: [
      //     {
      //       path: "/cadastrar-filial",
      //       title: "Nova Filial",
      //     },
      //     {
      //       path: "/filiais",
      //       title: "Filiais",
      //     },
      //   ],
      // },
      home: {
        title: "Home Dinâmica",
        subtitle: "Área de Home Dinâmica",
        icon: "fas fa-light fa-home",
        subItems: [
          {
            path: `${routerPath}/home-dinamica`,
            title: "Home Dinâmica",
          },
        ],
      },
      banners: {
        title: "Banners",
        subtitle: "Área de Banners",
        icon: "fas fa-light fa-image",
        subItems: [
          {
            path: `${routerPath}/cadastrar-banner`,
            title: "Novo Banner",
          },
          {
            path: `${routerPath}/banners`,
            title: "Banners",
          },
        ],
      },
      // categorias: {
      //   title: "Categorias",
      //   icon: "fas fa-th",
      //   subItems: [
      //     { path: "/cadastrar-categoria", title: "Nova Categoria" },
      //     { path: "/categorias", title: "Categorias" },
      //   ],
      // },
      // pedidos: {
      //   title: "Pedidos",
      //   subtitle: "",
      //   icon: "fas fa-clipboard-list",
      //   subItems: [{ path: "/pedidos", title: "Pedidos" }],
      // },
      // produtos: {
      //   title: "Produtos",
      //   icon: "fab fa-product-hunt",
      //   subItems: [
      // { path: "/cadastrar-produtos", title: "Novo Produto" },
      // { path: "/cadastrar-variacao-produtos", title: "Nova Variação" },
      // { path: "/produtos", title: "Produtos" },
      // {
      //   path: "/gerenciar-comentarios",
      //   title: "Comentários",
      // },
      // { path: "/cadastrar-marcas", title: "Nova Marca" },
      // { path: "/marcas", title: "Marcas" },
      // ],
      // },
      assistencia: {
        title: "Assistência",
        subtitle: "Assistência",
        icon: "fas fa-light fa-wrench",
        subItems: [
          {
            path: `${routerPath}/cadastrar-assistencia`,
            title: "Nova Assistência",
          },
          {
            path: `${routerPath}/assistencias`,
            title: "Assistências",
          },
        ]
      },
      ourStores: {
        title: "Lojas Físicas",
        subtitle: "Área de filial",
        icon: "fas fa-store",
        subItems: [
          {
            path: `${routerPath}/cadastrar-loja`,
            title: "Nova loja",
          },
          {
            path: `${routerPath}/lojas`,
            title: "Lojas",
          },
        ],
      },
      noticia: {
        title: "Notícias/Eventos",
        subtitle: "Notícias/Eventos",
        icon: "fas fa-light fa-calendar",
        subItems: [
          {
            path: `${routerPath}/cadastrar-noticia`,
            title: "Nova Notícia/Evento",
          },
          {
            path: `${routerPath}/noticias`,
            title: "Notícia/Evento",
          },
        ]
      },
      // evento: {
      //   title: "Feiras e Eventos",
      //   subtitle: "Feiras e Eventos",
      //   icon: "fas fa-light fa-newspaper",
      //   subItems: [
      //     {
      //       path: "/cadastrar-evento",
      //       title: "Nova Feira ou Evento",
      //     },
      //     {
      //       path: "/eventos",
      //       title: "Feiras e Eventos",
      //     },
      //   ]
      // },
      catalogos: {
        title: "Catálogos",
        icon: "fas fa-layer-group",
        subItems: [
          // { path: "/cadastrar-catalogos", title: "Novo Catálogo" },
          { path: `${routerPath}/catalogos`, title: "Catálogos" },
        ],
      },
      videos: {
        title: "Vídeos",
        icon: "fab fa-youtube",
        subItems: [
          // { path: "/cadastrar-videos", title: "Novo Vídeo" },
          { path: `${routerPath}/videos`, title: "Vídeos" },
          { path: `${routerPath}/videos-destaque`, title: "Vídeos em Destaque" },
        ]
      },
      exams: {
        title: "Avaliações",
        icon: "fas fa-book",
        subItems: [
          { path: `${routerPath}/cadastrar-aluno`, title: "Novo Aluno" },
          { path: `${routerPath}/alunos`, title: "Alunos" },
        ]
      },
      // campanha: {
      //   title: "Campanhas",
      //   icon: "fas fa-tags",
      //   subItems: [
      //     { path: "/cadastrar-campanha", title: "Nova Campanha" },
      //     { path: "/campanhas", title: "Campanhas" },
      //   ],
      // },
      // colors: {
      //   title: "Atributos",
      //   subtitle: "Área de Cores",
      //   icon: "fas fa-light fa-palette",
      //   subItems: [
      //     {
      //       path: "/cadastrar-cor",
      //       title: "Nova cor",
      //     },
      //     {
      //       path: "/cores",
      //       title: "Cores",
      //     },
      //     {
      //       path: "/cadastrar-tamanho",
      //       title: "Novo tamanho",
      //     },
      //     {
      //       path: "/tamanhos",
      //       title: "Tamanhos",
      //     },
      //   ],
      // },
      // logistica: {
      //   title: "Logistica",
      //   subtitle: "",
      //   icon: "fas fa-truck",
      //   subItems: [
      //     {
      //       path: "/cadastrar-transportadora",
      //       title: "Nova Transportadora",
      //     },
      //     {
      //       path: "/transportadoras",
      //       title: "Transportadoras",
      //     },
      //     {
      //       path: "/cadastrar-faixa-frete",
      //       title: "Nova Faixa De Frete",
      //     },
      //     {
      //       path: "/faixas-frete",
      //       title: "Faixas De Frete",
      //     },
      //   ],
      // },
      // cupons: {
      //   title: "Cupons",
      //   icon: "fas fa-tags",
      //   subItems: [
      //     { path: "/cadastrar-cupom", title: "Novo Cupom" },
      //     { path: "/cupons", title: "Gerenciamento de Cupons" },
      //   ],
      // },
      // newsletter: {
      //   title: "Newsletter",
      //   subtitle: "Cadastro de Newsletter",
      //   icon: "fas fa-light fa-suitcase",
      //   subItems: [
      //     {
      //       path: "/newsLetter",
      //       title: "Nova Newsletter",
      //     },
      //     {
      //       path: "/newsletters",
      //       title: "Newsletter",
      //     },
      //   ],
      // },
      // vendas: {
      //   title: "Relatórios",
      //   subtitle: "Um painel completo para controlar seu site",
      //   icon: "fas fa-clone",
      //   subItems: [
      //     { path: "/vendas/general", title: "Vendas Geral" },
      //     { path: "/vendas/produto", title: "Vendas Produto" },
      //   ],
      // },
      // merchant: {
      //   title: "Merchant",
      //   subtitle: "Área de filial",
      //   icon: "fas fa-hammer",
      //   subItems: [
      //     { path: "/cadastrar-merchant", title: "Cadastro de Merchant" },
      //     { path: "/merchants", title: "Gerenciar Merchants" },
      //   ],
      // },
    });
  }
}

export default MenuStore;
