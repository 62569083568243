import BaseModel from "./BaseModel";

class ExamModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.student = data.student;
    this.questionCount = data.questionCount;
    this.correctAnswerCount = data.correctAnswerCount;
    this.questions = data.questions;
    this.total = `${data.correctAnswerCount} de ${data.questionCount}`;
    this.percent = `${(data.correctAnswerCount/data.questionCount)*100}%`;
  }
}

export default ExamModel;
