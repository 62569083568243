import BaseAPI, { URLS } from "./BaseAPI";

class StudentAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.STUDENTS}`, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a lista de alunos" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao carregar a lista de alunos" };
    }
  }

  static async getExamsByStudent(uuidStudent) {
    try {
      const response = await BaseAPI.get(`${URLS.STUDENTS}/${uuidStudent}/exams`);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a lista de provas" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao carregar a lista de provas" };
    }
  }

  static async getExam(uuidStudent, uuidExam) {
    try {
      const response = await BaseAPI.get(`${URLS.STUDENTS}/${uuidStudent}/exams/${uuidExam}`);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a prova" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao carregar a prova" };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.STUDENTS, data);
      return response.status === 201 || response.status === 200
        ? response.data
        : { error: "Falha ao salvar aluno" };
    } catch (e) {
      console.log(e?.response?.data?.message);
      return e?.response?.status === 400 || e?.response?.status === 409
        ? { error: e?.response?.data?.message ?? "Falha ao salvar aluno" }
        : { error: "Falha ao salvar aluno" };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.STUDENTS}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: "Falha ao atualizar aluno" };
    } catch (e) {
      console.log(e.message);
      return e?.response?.status === 400 || e?.response?.status === 409
      ? { error: e?.response?.data?.message ?? "Falha ao salvar aluno" }
      : { error: "Falha ao salvar aluno" };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.STUDENTS}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar aluno" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao carregar aluno" };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.STUDENTS}/${uuid}`);
      return response.status === 200 || response.status === 204
        ? response.data
        : { error: "Falha ao deletar aluno" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao deletar aluno" };
    }
  }

}

export default StudentAPI;
