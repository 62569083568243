import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import LoadingComponent from "~/components/LoadingComponent/LoadingComponent";
import ManagerOurStoresContainer from "./containers/ManagerOurStoresContainer/ManagerOurStoresContainer";

//Imports apenas importados caso forem requisitados pela aplicação.
const HomePage = lazy(() => import("~/pages/HomePage/HomePage"));
const NotFound = lazy(() => import("~/pages/NotFound/NotFound"));
const LoginCenterPage = lazy(() =>
  import("~/pages/LoginCenterPage/LoginCenterPage")
);

//Componenentes de acordo ao item de menu

const AdministratorContainer = lazy(() =>
  import("~/containers/AccountContainer")
);
const MerchantContainer = lazy(() => import("~/containers/MerchantContainer"));

const ManagerAccountContainer = lazy(() =>
  import("~/containers/ManagerAccountContainer")
);
const ManagerMerchantContainer = lazy(() =>
  import("~/containers/ManagerMerchantContainer")
);
const ManagerCategoryContainer = lazy(() =>
  import("~/containers/ManagerCategoryContainer")
);
const CategoryContainer = lazy(() => import("~/containers/CategoryContainer"));

const ManagerProductsContainer = lazy(() =>
  import("~/containers/ManagerProductsContainer")
);
const ProductContainer = lazy(() => import("~/containers/ProductContainer"));
const ProductVariationContainer = lazy(() =>
  import("~/containers/ProductVariationContainer")
);

const ManagerVideoContainer = lazy(() =>
  import("~/containers/ManagerVideoContainer")
);
const VideoContainer = lazy(() => import("~/containers/VideoContainer"));

const FeaturedVideosContainer = lazy(() => import("~/containers/FeaturedVideosContainer"));

const OurStoresContainer = lazy(() =>
  import("~/containers/OurStoresContainer")
);

const BranchesContainer = lazy(() => import("~/containers/BranchesContainer"));

const ManagerBranchesContainer = lazy(() =>
  import("~/containers/ManagerBranchesContainer")
);

const BannerContainer = lazy(() => import("~/containers/BannerContainer"));

const ManagerBannersContainer = lazy(() =>
  import("~/containers/ManagerBannersContainer")
);

const AssistanceContainer = lazy(() =>
  import("~/containers/AssistanceContainer")
);

const ManagerAssistanceContainer = lazy(() =>
  import("~/containers/ManagerAssistanceContainer")
);

const ManagerStudentContainer = lazy(() =>
  import("~/containers/ManagerStudentContainer")
);

const StudentContainer = lazy(() =>
  import("~/containers/StudentContainer")
);

const ExamsByStudentContainer = lazy(() =>
  import("~/containers/ExamsByStudentContainer")
);

const ExamContainer = lazy(() =>
  import("~/containers/ExamContainer")
);

const CatalogosContainer = lazy(() => import("~/containers/CatalogosContainer"));

const ManagerCatalogosContainer = lazy(() =>
  import("~/containers/ManagerCatalogosContainer")
);

const ManagerDynamicHomeContainer = lazy(() =>
  import("~/containers/ManagerDynamicHomeContainer")
);

const ManagerCustomerContainer = lazy(() =>
  import("~/containers/ManagerCustomerContainer")
);

const DynamicHomeContainer = lazy(() =>
  import("~/containers/DynamicHomeContainer")
);

const ColorContainer = lazy(() => import("~/containers/ColorContainer"));
const ManagerColorContainer = lazy(() =>
  import("~/containers/ManagerColorContainer")
);

const ManagerSizeContainer = lazy(() =>
  import("~/containers/ManagerSizeContainer")
);
const SizeContainer = lazy(() => import("~/containers/SizeContainer"));

const NewsLetterContainer = lazy(() =>
  import("~/containers/NewsLetterContainer")
);

const CustomerContainer = lazy(() => import("~/containers/CustomerContainer"));

const NewsLetterEditorContainer = lazy(() =>
  import("~/containers/NewsLetterEditorContainer")
);

const ManagerCampaignContainer = lazy(() =>
  import("~/containers/ManagerCampaignContainer")
);

const CampaignContainer = lazy(() => import("~/containers/CampaignContainer"));
const ManagerProductCommentsContainer = lazy(() =>
  import("~/containers/ManagerProductCommentsContainer")
);

const ManagerSalesContainer = lazy(() =>
  import("~/containers/ManagerSalesContainer")
);

const ManagerReportProductContainer = lazy(() =>
  import("~/containers/ManagerReportProductContainer")
);

//Cupom
const ManagerCouponContainer = lazy(() =>
  import("~/containers/ManagerCouponContainer")
);

const CouponContainer = lazy(() => import("~/containers/CouponContainer"));

//Trasportadora
const ManagerShippingClassContainer = lazy(() =>
  import("~/containers/ManagerShippingClassContainer")
);

const ShippingClassContainer = lazy(() =>
  import("~/containers/ShippingClassContainer")
);

//Pedidos
const ManagerOrderContainer = lazy(() =>
  import("~/containers/ManagerOrderContainer")
);

const OrderDetailContainer = lazy(() =>
  import("~/containers/OrderDetailContainer")
);

//ZipCode
const ZipCodeRangeContainer = lazy(() =>
  import("~/containers/ZipCodeRangeContainer")
);

const ManagerZipCodeRangeContainer = lazy(() =>
  import("~/containers/ManagerZipCodeRangerContainer")
);

//Brand
const BrandCreateContainer = lazy(() =>
  import("~/containers/BrandCreateContainer")
);

const ManagerBrandContainer = lazy(() =>
  import("~/containers/ManagerBrandContainer")
);

// Notícias

const ManagerNewsContainer = lazy(() =>
  import("~/containers/ManagerNewsContainer")
);

const NewsContainer = lazy(() =>
  import("~/containers/NewsContainer")
);


const ManagerEventContainer = lazy(() =>
  import("~/containers/ManagerEventContainer")
);

const EventContainer = lazy(() =>
  import("~/containers/EventContainer")
);

//Rotas globais de nossa aplicação manager.
export default inject(({ authStore }) => ({ authStore }))(
  withRouter(
    observer((props) => {
      //função logout
      const logout = () => {
        props.authStore.logout().then(() => props.history.push("/"));
      };

      const routerPath = process.env.REACT_APP_ROUTE_PATH || '';
      console.log("routerPath", routerPath);
      return (
        <Suspense fallback={<LoadingComponent />}>
          <Switch>
            <Route exact path={`${routerPath}/error-page`} component={NotFound} />
            <Route exact path="/login" render={() => <Redirect to={"/administradores"} />} />
            <Route exact path="/logout" render={() => logout()} />

            <Route exact path={`${routerPath}/login`} component={LoginCenterPage} />

            {/* Definir LoginCenterPage como página inicial */}
            <Redirect exact from="/" to={`${routerPath}/login`} />
           
            <PrivateRoute
              component={HomePage}
              isAuthenticated={props.authStore.isAuthenticated}
            />
          </Switch>
        </Suspense>
      );
    })
  )
);

export const HomeRoutes = ({ routerPath }) => (
  <Switch>
    <Route exact path={`${routerPath}/cadastrar-merchant`} component={MerchantContainer} />
    <Route
      exact
      path={`${routerPath}/cadastrar-administrador`}
      component={AdministratorContainer}
    />

    <Route
      exact
      path={`${routerPath}/editar-merchant/:uuidMerchant`}
      component={MerchantContainer}
    />
    <Route
      exact
      path={`${routerPath}/editar-administrador/:uuidAdministrator`}
      component={AdministratorContainer}
    />

    <Route exact path={`${routerPath}/merchants`} component={ManagerMerchantContainer} />
    <Route exact path={`${routerPath}/administradores`} component={ManagerAccountContainer} />
    {/* <Route exact path="/categorias" component={ManagerCategoryContainer} />
    <Route exact path="/cadastrar-categoria" component={CategoryContainer} />
    <Route
      exact
      path="/editar-categoria/:uuidCategory"
      component={CategoryContainer}
    /> */}

    <Route exact path={`${routerPath}/assistencias`} component={ManagerAssistanceContainer} />
    <Route
      exact
      path={`${routerPath}/cadastrar-assistencia`}
      component={AssistanceContainer}
    />
    <Route
      exact
      path={`${routerPath}/editar-assistencia/:uuidAssistance`}
      component={AssistanceContainer}
    />

    <Route exact path={`${routerPath}/alunos`} component={ManagerStudentContainer} />
    <Route
      exact
      path={`${routerPath}/cadastrar-aluno`}
      component={StudentContainer}
    />
    <Route
      exact
      path={`${routerPath}/editar-aluno/:uuidStudent`}
      component={StudentContainer}
    />
    <Route
      exact
      path={`${routerPath}/resultados/:uuidStudent`}
      component={ExamsByStudentContainer}
    />

    <Route
      exact
      path={`${routerPath}/detalhes-prova/:uuidStudent/:uuidExam`}
      component={ExamContainer}
    />

    {/* <Route exact path="/produtos" component={ManagerProductsContainer} />
    <Route exact path="/cadastrar-produtos" component={ProductContainer} />
    <Route
      exact
      path="/editar-produto/:uuidProduct"
      component={ProductContainer}
    />
    <Route
      exact
      path="/cadastrar-variacao-produtos"
      component={ProductVariationContainer}
    />
    <Route
      exact
      path="/editar-variacao-produto/:uuidProduct"
      component={ProductVariationContainer}
    /> */}

    <Route exact path={`${routerPath}/videos`} component={ManagerVideoContainer} />
    <Route exact path={`${routerPath}/videos-destaque`} component={FeaturedVideosContainer} />
    <Route exact path={`${routerPath}/cadastrar-videos`} component={VideoContainer} />
    <Route
      exact
      path={`${routerPath}/editar-videos/:videoUuid`}
      component={VideoContainer}
    />

    <Route exact path={`${routerPath}/cadastrar-loja`} component={OurStoresContainer} />
    <Route
      exact
      path={`${routerPath}/editar-loja/:uuidOurStore`}
      component={OurStoresContainer}
    />
    <Route exact path={`${routerPath}/lojas`} component={ManagerOurStoresContainer} />

    <Route exact path={`${routerPath}/cadastrar-filial`} component={BranchesContainer} />
    <Route
      exact
      path={`${routerPath}/editar-filial/:uuidBranch`}
      component={BranchesContainer}
    />
    <Route exact path={`${routerPath}/filiais`} component={ManagerBranchesContainer} />

    <Route exact path={`${routerPath}/cadastrar-banner`} component={BannerContainer} />
    <Route
      exact
      path={`${routerPath}/editar-banner/:bannerUuid`}
      component={BannerContainer}
    />
    <Route exact path={`${routerPath}/banners`} component={ManagerBannersContainer} />

    {/* <Route exact path="/cadastrar-catalogos" component={CatalogosContainer} /> */}
    <Route
      exact
      path={`${routerPath}/editar-catalogos/:catalogoUuid`}
      component={CatalogosContainer}
    />
    <Route exact path={`${routerPath}/catalogos`} component={ManagerCatalogosContainer} />

    <Route
      exact
      path={`${routerPath}/home-dinamica`}
      component={ManagerDynamicHomeContainer}
    />
    <Route exact path={`${routerPath}/newsletters`} component={NewsLetterContainer} />
    <Route path={`${routerPath}/newsletter/:id?`} component={NewsLetterEditorContainer} />
    <Route
      exact
      path={`${routerPath}/editar-home-dinamica/:branchUuid`}
      component={DynamicHomeContainer}
    />

    <Route
      exact
      path={`${routerPath}/cadastrar-home-dinamica/`}
      component={DynamicHomeContainer}
    />

    <Route exact path="${routerPath}/cores" component={ManagerColorContainer} />
    <Route exact path="${routerPath}/cadastrar-cor" component={ColorContainer} />
    <Route exact path="${routerPath}/editar-cor/:uuidColor" component={ColorContainer} />

    <Route exact path="${routerPath}/tamanhos" component={ManagerSizeContainer} />
    <Route exact path="${routerPath}/cadastrar-tamanho" component={SizeContainer} />
    <Route exact path="${routerPath}/editar-tamanho/:uuidSize" component={SizeContainer} />

    <Route exact path="${routerPath}/campanhas" component={ManagerCampaignContainer} />
    <Route exact path="${routerPath}/cadastrar-campanha" component={CampaignContainer} />
    <Route
      exact
      path="${routerPath}/editar-campanha/:campaignUuid"
      component={CampaignContainer}
    />
    <Route
      exact
      path="${routerPath}/gerenciar-comentarios"
      component={ManagerProductCommentsContainer}
    />

    <Route exact path="${routerPath}/clientes" component={ManagerCustomerContainer} />
    <Route
      exact
      path="${routerPath}/cadastrar-clientes-novo"
      component={CustomerContainer}
    />

    <Route
      exact
      path="${routerPath}/editar-cliente/:uuidCustomer"
      component={CustomerContainer}
    />

    <Route exact path="${routerPath}/vendas/general" component={ManagerSalesContainer} />
    <Route
      exact
      path="${routerPath}/vendas/produto"
      component={ManagerReportProductContainer}
    />

    <Route
      exact
      path="${routerPath}/transportadoras"
      component={ManagerShippingClassContainer}
    />
    <Route
      exact
      path="${routerPath}/cadastrar-transportadora"
      component={ShippingClassContainer}
    />
    <Route
      exact
      path="${routerPath}/editar-transportadora/:uuidShipping"
      component={ShippingClassContainer}
    />

    <Route exact path="${routerPath}/cupons" component={ManagerCouponContainer} />
    <Route exact path="${routerPath}/cadastrar-cupom" component={CouponContainer} />
    <Route exact path="${routerPath}/pedidos" component={ManagerOrderContainer} />

    <Route
      exact
      path="${routerPath}/detalhes-pedido/:uuidOrder/user/:uuidUser"
      component={OrderDetailContainer}
    />

    <Route
      exact
      path="${routerPath}/faixas-frete"
      component={ManagerZipCodeRangeContainer}
    />
    <Route
      exact
      path="${routerPath}/cadastrar-faixa-frete"
      component={ZipCodeRangeContainer}
    />
    <Route
      exact
      path="${routerPath}/editar-faixa-frete/:uuidZipCodeRange"
      component={ZipCodeRangeContainer}
    />

    <Route exact path="${routerPath}/cadastrar-marcas" component={BrandCreateContainer} />
    <Route
      exact
      path="${routerPath}/editar-marca/:uuidBrand"
      component={BrandCreateContainer}
    />
    <Route exact path="${routerPath}/marcas" component={ManagerBrandContainer} />

    <Route exact path={`${routerPath}/noticias`} component={ManagerNewsContainer} />
    <Route exact path={`${routerPath}/cadastrar-noticia`} component={NewsContainer} />
    <Route exact path={`${routerPath}/editar-noticia/:uuid`} component={NewsContainer} />


    <Route exact path={`${routerPath}/eventos`} component={ManagerEventContainer} />
    <Route exact path={`${routerPath}/cadastrar-evento`} component={EventContainer} />
    <Route exact path={`${routerPath}/editar-evento/:uuid`} component={EventContainer} />
  </Switch>
);

/*Private route é responsável por verificar se o usuário está logado ou não. */
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <>
          {console.log('props', props)}
          <Component {...props} />
        </>
      ) : (
        <LoginCenterPage {...props} />
      )
    }
  />
);
